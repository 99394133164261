import { globals } from "@/globals";
import * as signals from "@/signals";
import { Dialog, DialogTitle } from "@headlessui/react";
import { useSignalEffect } from "@preact/signals";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, MouseEvent, useRef, useState } from "react";

function MessageDialog(): JSX.Element {
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const timeoutId = useRef<number | undefined>(undefined);

	const closeDialog = (): void => {
		window.clearTimeout(timeoutId.current);
		globals.dialogCallbacks = {};
		setIsClosing(true);
		setTimeout(() => {
			signals.dialog.value = {};
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleCancelClick = (event: MouseEvent<HTMLButtonElement>): void => {
		void globals.dialogCallbacks.cancel?.(event);
		closeDialog();
	};

	const handleOkClick = (event: MouseEvent<HTMLButtonElement>): void => {
		void globals.dialogCallbacks.ok?.(event);
		closeDialog();
	};

	useSignalEffect(() => {
		window.clearTimeout(timeoutId.current);
		if (signals.dialog.value.countdown) {
			timeoutId.current = window.setTimeout(() => {
				const dialog = signals.dialog.value;
				signals.dialog.value = {
					...dialog,
					countdown:
						dialog.countdown && dialog.countdown > 0
							? dialog.countdown - 1
							: 0,
				};
			}, 1000);
		}
		return (): void => {
			window.clearTimeout(timeoutId.current);
		};
	});

	return (
		<Dialog
			onClose={closeDialog}
			open={!!signals.dialog.value.text}
		>
			<div
				className={clsx(
					"overlay",
					!isClosing && "zoom-in",
					isClosing && "zoom-out",
				)}
			>
				<div
					className={clsx(
						"popup",
						"msg-box",
						signals.dialog.value.text &&
							signals.dialog.value.text.length > 150 &&
							"wordy",
					)}
				>
					{signals.dialog.value.title && (
						<DialogTitle as="h1">
							{signals.dialog.value.title}
						</DialogTitle>
					)}
					<div className="msg-box-txt">
						{signals.dialog.value.isHtml &&
						signals.dialog.value.text ? (
							<span
								dangerouslySetInnerHTML={{
									__html: signals.dialog.value.text,
								}}
							></span>
						) : (
							signals.dialog.value.text
						)}
					</div>
					<div className="btn-bar">
						{signals.dialog.value.showCancel && (
							<button
								type="button"
								onClick={handleCancelClick}
							>
								{t("cancel")}
							</button>
						)}
						<button
							className="default-btn"
							data-autofocus
							disabled={!!signals.dialog.value.countdown}
							type="button"
							onClick={handleOkClick}
						>
							{t("ok")}
							{signals.dialog.value.countdown
								? ` (${signals.dialog.value.countdown})`
								: null}
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default MessageDialog;
