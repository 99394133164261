import ActionButton from "@/components/action-button";
import DeviceIcon from "@/components/device-icon";
import { DeviceInfo } from "@/types";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { JSX } from "react";

interface DeviceRowProps {
	item: DeviceInfo;
	handleLogOutClick: () => void;
}

function DeviceRow({ item, handleLogOutClick }: DeviceRowProps): JSX.Element {
	const { ip, userAgent } = item;

	return (
		<div className="row">
			<div className="row-body">
				<DeviceIcon userAgent={userAgent} />
				<div className="label">{ip}</div>
				<div className="content small">{userAgent}</div>
			</div>
			<ActionButton
				onClick={handleLogOutClick}
				title={t("logOut")}
			>
				<FontAwesomeIcon icon={faArrowRightFromBracket} />
			</ActionButton>
		</div>
	);
}

export default DeviceRow;
