import * as api from "@/api";
import ActionButton from "@/components/action-button";
import ButtonBlock from "@/components/button-block";
import DeviceRow from "@/components/device-row";
import DeviceRowCurrent from "@/components/device-row-current";
import ImportantTip from "@/components/important-tip";
import LoadingScreen from "@/components/loading-screen";
import QrCode from "@/components/qr-code";
import Switch from "@/components/switch";
import { importOtpAuth } from "@/dependencies";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { DeviceResponse, ProfileResponse } from "@/types";
import {
	changePhone,
	deleteAccount,
	encodeData,
	logOut,
	showDialog,
	showPrompt,
	sleep,
} from "@/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { ChangeEvent, JSX, useRef, useState } from "react";

function SecurityPage(): JSX.Element {
	const queryClient = useQueryClient();

	const {
		data: devices,
		error: devicesError,
		isLoading: isLoadingDevices,
	} = useQuery({
		queryFn: () => {
			if (!signals.login.value.username) {
				return null;
			}
			return api.getAllDevices(() => {
				void logOut();
			});
		},
		queryKey: ["devices", signals.login.value.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});
	const {
		data: profile,
		error: profileError,
		isLoading: isLoadingProfile,
	} = useQuery({
		queryFn: () => {
			if (!signals.login.value.username) {
				return null;
			}
			return api.getProfile(["realId", "regTime"], () => {
				void logOut();
			});
		},
		queryKey: ["profile", signals.login.value.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const [otpUri, setOtpUri] = useState<string>("");
	const [showTfaQrCode, setShowTfaQrCode] = useState<boolean>(false);

	const error = devicesError || profileError;
	const isLoading = isLoadingDevices || isLoadingProfile;
	const securityDiv = useRef<HTMLDivElement>(null);

	const confirmAllLogOut = async (): Promise<void> => {
		await showDialog(t("confirmAllLogOut"), { showCancel: true });
		signals.isLoadingScreenShown.value = true;
		try {
			await api.logOutAllDevices();
			localStorage.clear();
			window.location.reload();
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingScreenShown.value = false;
		}
	};

	const handleAllLogOutClick = (): void => {
		void confirmAllLogOut();
	};

	const handleDeleteAccountClick = (): void => {
		void deleteAccount();
	};

	const handleTfaChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (profile?.tfa) {
			void saveTfa("0");
			event.target.parentElement?.focus();
		} else {
			setupTotp();
		}
	};

	const logOutDevice = async (id: number): Promise<void> => {
		signals.isLoadingScreenShown.value = true;
		try {
			await api.logOutDevice(id);
			queryClient.setQueryData<DeviceResponse>(
				["devices", signals.login.value.username],
				(devices) => {
					if (!devices) {
						return devices;
					}
					return {
						...devices,
						other: devices.other.filter((item) => item.id !== id),
					};
				},
			);
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingScreenShown.value = false;
		}
		securityDiv.current?.focus();
	};

	const saveTfa = async (newValue: string): Promise<void> => {
		if (!signals.login.value.username) {
			return;
		}
		signals.isLoadingScreenShown.value = true;
		try {
			const data = await api.updateTfa(newValue);
			if (data.error) {
				switch (data.error) {
					case "notAllowed": {
						void showDialog(t("cannotTurnOffTfa"));
						break;
					}
					case "phoneRequired": {
						void changePhone();
						break;
					}
					default: {
						void showDialog(data.error);
						break;
					}
				}
			} else {
				queryClient.setQueryData<ProfileResponse>(
					["profile", signals.login.value.username],
					(profile) => {
						if (!profile) {
							return;
						}
						return { ...profile, tfa: newValue !== "0" };
					},
				);
			}
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingScreenShown.value = false;
		}
	};

	const setupTotp = (): void => {
		const login = signals.login.value;
		if (showTfaQrCode) {
			setShowTfaQrCode(false);
			return;
		} else if (profile?.tfa || !login.username) {
			return;
		}
		const secret = getRandomBase32(16);
		setOtpUri(
			"otpauth://totp/retiehe.com:" +
				encodeURIComponent(
					login.email || login.phone || login.username,
				) +
				"?" +
				encodeData({ issuer: "retiehe.com", secret: secret }),
		);
		setShowTfaQrCode(true);
	};

	const verifyOtp = async (): Promise<void> => {
		const OTPAuth = await importOtpAuth();
		const otpAuth = OTPAuth.URI.parse(otpUri);
		const verifyOtpPrompt = async (): Promise<void> => {
			const code = await showPrompt(
				t("enterCodeFromGoogleAuthenticator"),
				{ autoComplete: "one-time-code", type: "tel" },
			);
			const correctCode = otpAuth.generate();
			if (code === correctCode) {
				setShowTfaQrCode(false);
				void saveTfa(otpAuth.secret.base32);
			} else {
				await sleep(globals.ANIMATION_WAIT_TIME);
				await showDialog(t("incorrectVerificationCode"));
				await sleep(globals.ANIMATION_WAIT_TIME);
				void verifyOtpPrompt();
			}
		};
		void verifyOtpPrompt();
	};

	const deviceRowElem = devices?.other.map((item) => {
		return (
			<DeviceRow
				item={item}
				key={item.id}
				handleLogOutClick={(): void => {
					void logOutDevice(item.id);
				}}
			/>
		);
	});

	return (
		<main
			ref={securityDiv}
			className="security"
		>
			<section>
				<Switch
					checked={profile?.tfa || false}
					onChange={handleTfaChange}
					title={t("tfaSwitch")}
				/>
				<h2>{t("tfa")}</h2>
				<div className="description">{t("tfaDescription")}</div>
			</section>
			<div className="line"></div>
			<QrCode
				buttonText="next"
				description="scanThisQrCodeUsingGoogleAuthenticator"
				qrCode={otpUri}
				scrollIntoView={false}
				show={showTfaQrCode}
				onClick={() => void verifyOtp()}
			/>
			<section>
				<div className="section-heading-container">
					<h2>{t("devices")}</h2>
					<ActionButton onClick={handleAllLogOutClick}>
						{t("allLogOut")}
					</ActionButton>
				</div>
				<div className="device-rows">
					{devices?.current && (
						<DeviceRowCurrent item={devices.current} />
					)}
					{deviceRowElem}
				</div>
			</section>
			<div className="line"></div>
			<section>
				<h2>{t("deleteAccount")}</h2>
				<div className="description">
					{t("deleteAccountDescription")}
				</div>
				<ButtonBlock
					className="danger"
					onClick={handleDeleteAccountClick}
				>
					{t("deleteAccount")}
				</ButtonBlock>
			</section>
			{error && (
				<ImportantTip
					text={error.message}
					title={t("error")}
				/>
			)}
			{isLoading && <LoadingScreen />}
		</main>
	);
}

function getRandomBase32(length: number): string {
	const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567".split("");
	let result = "";
	for (let i = 0; i < length; i++) {
		result += getRandomArrayItem<string>(chars);
	}
	return result;
}

function getRandomArrayItem<T>(array: T[]): T {
	if (!array) {
		return array;
	}
	return array[Math.floor(Math.random() * array.length)];
}

export default SecurityPage;
