import { PaymentMethodInfo } from "@/types";
import { showDialog } from "@/utils";
import { t } from "i18next";
import { MouseEvent } from "react";

export const globals = {
	ANIMATION_WAIT_TIME: 250 as const,
	APP_NAME: "account" as const,
	backend: "https://api.retiehe.com/backend",
	captchaCallback: null as ((data: CaptchaResponse) => void) | null,
	dialogCallbacks: {} as Record<
		string,
		(event?: MouseEvent) => void | Promise<void>
	>,
	emailPattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]*\.)+[A-Za-z]{2,14}/,
	home: "https://www.retiehe.com/",
	isAndroidApp: navigator.userAgent.includes("APAndroid/"),
	isDesktopApp: navigator.userAgent.includes("Electron"),
	isIosApp: navigator.userAgent.includes("APIos/"),
	isMobile:
		navigator.userAgent.includes("Android") ||
		navigator.userAgent.includes("iPhone") ||
		navigator.userAgent.includes("iPad"),
	loadedJs: new Set<string>(),
	methodMap: {
		alipay: {
			callback: async ({ url, setShowCashier }): Promise<void> => {
				setShowCashier(true);
				if (globals.isMobile) {
					window.location.href = url;
				} else {
					await showDialog(t("goingToAlipayCashier"));
					window.open(url);
				}
			},
		} as PaymentMethodInfo,
		wechatpay: {
			callback: async ({
				url,
				setQrCode,
				setShowCashier,
			}): Promise<void> => {
				setShowCashier(true);
				if (url.startsWith("http")) {
					await showDialog(t("wechatWillBeCalled"));
					window.location.href = url;
				} else {
					setQrCode?.(url);
				}
			},
		} as PaymentMethodInfo,
	},
	params: Object.fromEntries(
		new URLSearchParams(window.location.search).entries(),
	) as Record<string, string | undefined>,
	phonePattern: /^\d{11}$/,
	promptCallback: null as ((newValue: string) => void | Promise<void>) | null,
	promptInlineAction: null as (() => void | Promise<void>) | null,
};

if (process.env.NODE_ENV === "development" || globals.params.test === "1") {
	globals.backend =
		process.env.RTH_BACKEND || "https://api-dev.retiehe.xyz/backend";
}
