import App from "@/components/app";
import { globals } from "@/globals";
import "@/globals.css";
import translationEnUs from "@/translations/en-us.json";
import translationZhCn from "@/translations/zh-cn.json";
import translationZhTw from "@/translations/zh-tw.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faDisplay,
	faGlobe,
	faMobileScreenButton,
	faTabletScreenButton,
} from "@fortawesome/free-solid-svg-icons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18n, { t } from "i18next";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

function readSystemTheme(): boolean {
	const themeMatch = matchMedia("(prefers-color-scheme: dark)");
	themeMatch.onchange = (event): void => {
		if (event.matches) {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
	};
	return themeMatch.matches;
}

const themeParam = globals.params.theme;
if (
	themeParam === "dark" ||
	(((!themeParam && window.self === window.top) || themeParam === "system") &&
		readSystemTheme())
) {
	document.documentElement.classList.add("dark");
}

const i18nResources = {
	"en-US": { translation: translationEnUs },
	"zh-CN": { translation: translationZhCn },
	"zh-TW": { translation: translationZhTw },
};

const lang = ((): string => {
	const language = globals.params.lang;
	if (language && language in i18nResources) {
		return language;
	} else if (
		!navigator.language ||
		/^(yue|zh)(-cn|-sg|-hans(-[a-z]+)?)?$/i.test(navigator.language) ||
		(/bot|spider/i.test(navigator.userAgent) &&
			!navigator.userAgent.includes("Googlebot"))
	) {
		return "zh-CN";
	} else if (
		navigator.language.startsWith("zh") ||
		navigator.language.startsWith("yue")
	) {
		return "zh-TW";
	} else {
		return "en-US";
	}
})();

i18n.init({
	fallbackLng: "en-US",
	interpolation: { escapeValue: false },
	lng: lang,
	resources: i18nResources,
})
	.then(() => {
		document.title = t("retieheAccount");
		if (!lang.startsWith("zh")) {
			globals.home = "https://www.retinbox.com/";
		}
	})
	.catch(console.error);

library.add(faDisplay, faGlobe, faMobileScreenButton, faTabletScreenButton);

document.documentElement.lang = lang;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<QueryClientProvider client={queryClient}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</QueryClientProvider>,
);
