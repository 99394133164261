import styles from "@/components/important-tip.module.css";
import { t } from "i18next";
import { JSX } from "react";

interface ImportantTipProps {
	text: string;
	title?: string;
}

function ImportantTip({ text, title }: ImportantTipProps): JSX.Element {
	return (
		<div className={styles["tip-container"]}>
			<h4>{title || t("importantTip")}</h4>
			<p>{text}</p>
		</div>
	);
}

export default ImportantTip;
