import styles from "@/components/premium-row.module.css";
import { PremiumInfo } from "@/types";
import { faCheck, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { JSX, useState } from "react";

interface PremiumRowProps {
	item: PremiumInfo;
}

function PremiumRow({ item }: PremiumRowProps): JSX.Element {
	const [showPrivilegeInfo, setShowPrivilegeInfo] = useState<boolean>(false);

	const {
		appName,
		data,
		days,
		explanation,
		icon,
		iconFallback,
		link,
		privileges,
	} = item;

	const handleWhyPremiumClick = (): void => {
		setShowPrivilegeInfo(!showPrivilegeInfo);
	};

	const privilegesElem = privileges?.map((item) => {
		return (
			<div key={item}>
				<FontAwesomeIcon
					icon={faCheck}
					fixedWidth
				/>
				<span>{item}</span>
			</div>
		);
	});

	return (
		<>
			<div className="row">
				<div className="row-body">
					<span className="label">
						<a
							href={link}
							target="_blank"
							rel="noreferrer"
						>
							{iconFallback ? (
								<picture className={styles["product-icon"]}>
									<source
										srcSet={icon}
										type="image/webp"
									/>
									<img
										src={iconFallback}
										alt=""
										draggable="false"
									/>
								</picture>
							) : (
								<img
									className={styles["product-icon"]}
									src={icon}
									alt=""
									draggable="false"
								/>
							)}
							<span>{appName && t(appName)}</span>
						</a>
					</span>
					<span className="content">
						{days !== undefined &&
							t("premiumDaysRemaining", {
								days: days,
							})}
						{data !== undefined &&
							t("premiumDataRemaining", {
								data: data,
							})}
					</span>
				</div>
				<button
					className="action-button privilege"
					type="button"
					onClick={handleWhyPremiumClick}
				>
					<FontAwesomeIcon
						icon={faStar}
						fixedWidth
					/>
					<span>{t("whyPremium")}</span>
				</button>
			</div>
			<div
				className={styles["expansion-block"]}
				hidden={!showPrivilegeInfo}
			>
				{privilegesElem}
				<p
					dangerouslySetInnerHTML={{
						__html: explanation || "",
					}}
				></p>
			</div>
		</>
	);
}

export default PremiumRow;
