import { globals } from "@/globals";
import * as signals from "@/signals";
import { t } from "i18next";
import { JSX } from "react";

interface PromptInlineActionProps {
	closeDialog: () => void;
}

function PromptInlineAction({
	closeDialog,
}: PromptInlineActionProps): JSX.Element {
	const handleInlineActionClick = (): void => {
		void globals.promptInlineAction?.();
		closeDialog();
	};

	return (
		<>
			{" "}
			<span
				className={
					signals.prompt.value.countdown &&
					signals.prompt.value.countdown > 0
						? undefined
						: "link"
				}
				role="button"
				tabIndex={0}
				onClick={handleInlineActionClick}
				onKeyDown={handleInlineActionClick}
			>
				{signals.prompt.value.countdown &&
				signals.prompt.value.countdown > 0 &&
				signals.prompt.value.countdownText
					? t(signals.prompt.value.countdownText, {
							seconds: signals.prompt.value.countdown,
						})
					: signals.prompt.value.inlineActionName}
			</span>
		</>
	);
}

export default PromptInlineAction;
