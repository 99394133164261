import ActionButton from "@/components/action-button";
import { ProfileRowItem } from "@/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { JSX } from "react";

interface ProfileRowProps {
	item: ProfileRowItem;
}

function ProfileRow({ item }: ProfileRowProps): JSX.Element {
	const { action, content, icon, label } = item;

	const handleClick = (): void => {
		void action.onClick();
	};

	return (
		<div className="row">
			<div className="row-body">
				<span className="label">
					<FontAwesomeIcon
						icon={icon}
						size="xs"
						fixedWidth
					/>
					<span>{t(label)}</span>
				</span>
				<span className="content">{content}</span>
			</div>
			<ActionButton
				onClick={handleClick}
				title={t(action.title)}
			>
				{t(action.text)}
			</ActionButton>
		</div>
	);
}

export default ProfileRow;
