import * as api from "@/api";
import ImportantTip from "@/components/important-tip";
import LoadingScreen from "@/components/loading-screen";
import ProfileRow from "@/components/profile-row";
import RealIdVerification from "@/components/real-id-verification";
import * as signals from "@/signals";
import { ProfileResponse, ProfileRowItem, RealIdLevel } from "@/types";
import {
	changeEmail,
	changePhone,
	logOut,
	showDialog,
	showPrompt,
} from "@/utils";
import {
	faCakeCandles,
	faCalendarDay,
	faEarth,
	faEnvelope,
	faIdCard,
	faKey,
	faMobileScreen,
	faSignature,
	faUser,
	faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { JSX, useState } from "react";
import { useNavigate } from "react-router-dom";

interface JsxItem {
	jsx: JSX.Element;
}

function ProfilePage(): JSX.Element {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { data, error, isFetching, refetch } = useQuery({
		queryFn: () => {
			if (!signals.login.value.username) {
				return null;
			}
			return api.getProfile(["realId", "regTime"], () => {
				void logOut();
			});
		},
		queryKey: ["profile", signals.login.value.username],
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const [showRealIdVerification, setShowRealIdVerification] =
		useState<boolean>(false);

	const changeBirthday = async (): Promise<void> => {
		const newBirthday = await showPrompt(t("enterNewBirthday"), {
			defaultText:
				signals.login.value.birthday ||
				new Date().toISOString().split("T")[0],
		});
		if (!signals.login.value.username || !newBirthday) {
			return;
		}
		const newBirthdayDate = new Date(newBirthday);
		const formattedNewBirthday =
			newBirthdayDate.toString() !== "Invalid Date"
				? newBirthdayDate.toISOString().split("T")[0]
				: "";
		const year = newBirthdayDate.getFullYear();
		if (
			!formattedNewBirthday ||
			year < 1900 ||
			year > new Date().getFullYear()
		) {
			void showDialog(t("invalidDate"));
			return;
		}
		if (formattedNewBirthday !== signals.login.value.birthday) {
			signals.isLoadingScreenShown.value = true;
			try {
				await api.updateBirthday(formattedNewBirthday);
				signals.login.value = {
					...signals.login.value,
					birthday: formattedNewBirthday,
				};
			} catch (error) {
				api.handleApiError(error);
			} finally {
				signals.isLoadingScreenShown.value = false;
			}
		}
	};

	const changeName = async (): Promise<void> => {
		const newName = await showPrompt(t("enterNewName"), {
			defaultText: signals.login.value.name,
		});
		if (
			!signals.login.value.username ||
			!newName ||
			newName === signals.login.value.name
		) {
			return;
		}
		signals.isLoadingScreenShown.value = true;
		try {
			const data = await api.updateName(newName);
			if (data.alert) {
				void showDialog(data.alert);
			}
			if (!data.success) {
				return;
			}
			signals.login.value = {
				...signals.login.value,
				name: newName,
			};
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingScreenShown.value = false;
		}
	};

	const copyUserId = async (): Promise<void> => {
		if (!signals.login.value.username) {
			return;
		}
		try {
			if (!("clipboard" in navigator)) {
				throw new Error("Clipboard API not supported.");
			}
			await navigator.clipboard.writeText(signals.login.value.username);
			void showDialog(t("copiedToClipboard"));
		} catch (error) {
			console.error(error);
			void showPrompt(t("clipboardNotSupported"), {
				defaultText: signals.login.value.username,
			});
		}
	};

	const updateRegion = async (): Promise<void> => {
		// overseas account transfer to China
		if (
			signals.dynamicInfo.value?.region === "CN" &&
			!signals.login.value.phone
		) {
			return changePhone();
		}

		// China account transfer to overseas
		const isChinaTimeZone =
			Intl.DateTimeFormat().resolvedOptions().timeZone ===
			"Asia/Shanghai";
		if (data?.region === "CN" && isChinaTimeZone) {
			return void showDialog(
				t("regionUpdatedAs", {
					region: "CN",
				}),
			);
		}

		signals.isLoadingScreenShown.value = true;
		try {
			const data = await api.updateRegion();
			if (data.error) {
				return showDialog(data.error);
			}
			if (!data.success) {
				return;
			}
			queryClient.setQueryData<ProfileResponse>(
				["profile", signals.login.value.username],
				(profile) => {
					if (!profile || !data.newValue) {
						return;
					}
					return {
						...profile,
						region: data.newValue,
					};
				},
			);
			void showDialog(
				t("regionUpdatedAs", {
					region: data.newValue,
				}),
			);
		} catch (error) {
			api.handleApiError(error);
		} finally {
			signals.isLoadingScreenShown.value = false;
		}
	};

	const rows: (ProfileRowItem | JsxItem)[] = [
		{
			action: {
				onClick: () => void copyUserId(),
				text: "copy",
				title: "copyUserId",
			},
			content: signals.login.value.username,
			icon: faUser,
			label: "userId",
		},
		{
			action: {
				onClick: (): void => {
					void changeEmail(signals.login.value.email);
				},
				text: "change",
				title: "changeEmail",
			},
			content: signals.login.value.email,
			icon: faEnvelope,
			label: "email",
		},
		{
			action: {
				onClick: (): void => {
					void changePhone();
				},
				text: "change",
				title: "changePhoneNumber",
			},
			content: signals.login.value.phone,
			icon: faMobileScreen,
			label: "phoneNumber",
		},
		{
			action: {
				onClick: (): void => {
					void navigate("/.well-known/change-password", {
						state: {
							emailOrPhone:
								signals.login.value.email ||
								signals.login.value.phone,
						},
					});
				},
				text: "change",
				title: "changePassword",
			},
			content: signals.login.value.token ? "********" : "",
			icon: faKey,
			label: "password",
		},
		{
			action: {
				onClick: changeName,
				text: "change",
				title: "changeName",
			},
			content: signals.login.value.name,
			icon: faSignature,
			label: "name",
		},
		{
			action: {
				onClick: changeBirthday,
				text: "change",
				title: "changeBirthday",
			},
			content: signals.login.value.birthday,
			icon: faCakeCandles,
			label: "birthday",
		},
		{
			action: {
				onClick: updateRegion,
				text: "update",
				title: "",
			},
			content: data?.region,
			icon: faEarth,
			label: "region",
		},
		{
			action: {
				onClick: (): void => {
					setShowRealIdVerification(!showRealIdVerification);
				},
				text: "change",
				title: "changeRealId",
			},
			content:
				data?.realName !== undefined
					? data.realName || t("idCardNotUploaded")
					: undefined,
			icon: faIdCard,
			label: "realId",
		},
		{
			jsx: (
				<RealIdVerification
					callback={() => void refetch()}
					key="qrCodeForRealIdVerification"
					show={showRealIdVerification}
				/>
			),
		},
		{
			action: {
				onClick: (): void => {
					void showDialog(t("realIdLevelDescription"));
				},
				text: "details",
				title: "realIdLevelDetails",
			},
			content:
				data?.realIdLevel !== undefined
					? data.realIdLevel > RealIdLevel.NO_REAL_ID
						? t("level" + data.realIdLevel)
						: t("noRealId")
					: undefined,
			icon: faUserCheck,
			label: "realIdLevel",
		},
		{
			action: {
				onClick: (): void => {
					const details = data?.regTime?.details;
					if (!details) {
						return;
					}
					void showDialog(details);
				},
				text: "details",
				title: "regTimeDetails",
			},
			content: data?.regTime?.time,
			icon: faCalendarDay,
			label: "regTime",
		},
	];
	const rowsElem = rows.map((item) => {
		if (item.jsx) {
			return item.jsx;
		}
		const profileRowItem = item as ProfileRowItem;
		return (
			<ProfileRow
				item={profileRowItem}
				key={profileRowItem.label}
			/>
		);
	});

	return (
		<main className="profile">
			{rowsElem}
			{error && (
				<ImportantTip
					text={error.message}
					title={t("error")}
				/>
			)}
			{isFetching && <LoadingScreen />}
		</main>
	);
}

export default ProfilePage;
