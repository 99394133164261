import * as api from "@/api";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { DialogInfo, PromptInfo, WindowExt } from "@/types";
import { t } from "i18next";
import { JSX } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const windowExt: WindowExt = window;

export function addResendBtn(parent: HTMLElement, callback: () => void): void {
	const newResendA = document.createElement("a");
	let remainingSeconds = 60;

	const handleClick = (): void => {
		if (remainingSeconds <= 0) {
			callback();
		}
	};

	newResendA.onclick = handleClick;
	newResendA.onkeydown = handleKeyboardClick(handleClick);
	parent.appendChild(newResendA);
	const intervalId = setInterval(() => {
		if (remainingSeconds > 0 && newResendA.parentElement) {
			remainingSeconds--;
			newResendA.textContent = t("resendAfter", {
				seconds: remainingSeconds,
			});
		} else {
			clearInterval(intervalId);
			newResendA.textContent = t("resend");
		}
	}, 1000);
}

export async function changeEmail(defaultEmail = ""): Promise<void> {
	const login = signals.login.value;

	const newEmail = await showPrompt(t("enterNewEmailAddress"), {
		defaultText: defaultEmail,
		type: "email",
	});
	if (newEmail === login.email) {
		await sleep(globals.ANIMATION_WAIT_TIME);
		void showDialog(t("oldAndNewEmailAddressesSame"));
		return;
	}
	if (!globals.emailPattern.test(newEmail)) {
		await sleep(globals.ANIMATION_WAIT_TIME);
		void showDialog(t("incorrectEmailAddressFormat"));
		return;
	}
	await sleep(globals.ANIMATION_WAIT_TIME);
	await showDialog(
		t("confirmEmailAddress") + newEmail,
		{ showCancel: true },
		{
			cancel: async () => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void changeEmail(newEmail);
			},
		},
	);
	await sleep(globals.ANIMATION_WAIT_TIME);
	const password = await showPrompt(t("enterThePassword"), {
		type: "password",
	});

	const resend = async (email: string): Promise<void> => {
		const captchaResponse = await showCaptcha();
		signals.isLoadingScreenShown.value = true;
		try {
			const updateEmailResult = await api.updateEmail({
				captchaResponse: captchaResponse,
				newEmail: email,
				password: password,
			});
			signals.isLoadingScreenShown.value = false;
			if (updateEmailResult.alert) {
				await showDialog(updateEmailResult.alert, {
					isHtml: true,
				});
			}
			if (!updateEmailResult.success) {
				return;
			}
			const code = await showPrompt(
				t("enterCode", { recipient: email }),
				{
					countdown: 60,
					countdownText: "resendAfter",
					inlineActionName: t("resend"),
				},
				() => {
					void resend(email);
				},
			);
			if (code.length !== 6) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(t("verificationFailed"));
				return;
			}
			signals.isLoadingScreenShown.value = true;
			const verifyEmailResult = await api.verifyEmail(code);
			signals.isLoadingScreenShown.value = false;
			if (verifyEmailResult.alert) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(verifyEmailResult.alert);
			}
			if (!verifyEmailResult.success) {
				return;
			}
			signals.login.value = {
				...login,
				email: email,
			};
		} catch (error) {
			api.handleApiError(error);
			signals.isLoadingScreenShown.value = false;
		}
	};

	void resend(newEmail);
}

export async function changePhone(defaultPhoneNumber = ""): Promise<void> {
	const login = signals.login.value;

	const newPhoneNumber = await showPrompt(t("enterNewPhoneNumber"), {
		defaultText: defaultPhoneNumber,
		type: "tel",
	});
	await sleep(globals.ANIMATION_WAIT_TIME);
	const formattedNewPhoneNumber = formatPhoneNumber(newPhoneNumber);
	if (!formattedNewPhoneNumber) {
		void showDialog(t("incorrectPhoneNumberFormat"));
		return;
	}
	await showDialog(
		t("confirmPhoneNumber") + formattedNewPhoneNumber,
		{ showCancel: true },
		{
			cancel: async () => {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void changePhone(formattedNewPhoneNumber);
			},
		},
	);
	await sleep(globals.ANIMATION_WAIT_TIME);
	const password = await showPrompt(t("enterThePassword"), {
		type: "password",
	});

	const resend = async (phone: string): Promise<void> => {
		const emailOrPhone = login.email || login.phone;
		if (!emailOrPhone) {
			return;
		}
		const captchaResponse = await showCaptcha();
		signals.isLoadingScreenShown.value = true;
		try {
			const setPhoneResult = await api.setPhone({
				captchaResponse: captchaResponse,
				emailOrPhone: emailOrPhone,
				newPhone: phone,
				password: password,
			});
			signals.isLoadingScreenShown.value = false;
			if (setPhoneResult.alert) {
				await showDialog(setPhoneResult.alert);
			}
			if (!setPhoneResult.success) {
				return;
			}
			const code = await showPrompt(
				t("enterCode", { recipient: phone }),
				{
					countdown: 60,
					countdownText: "resendAfter",
					inlineActionName: t("resend"),
				},
				() => {
					void resend(phone);
				},
			);
			if (code.length !== 6) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(t("verificationFailed"));
				return;
			}
			signals.isLoadingScreenShown.value = true;
			const verifyPhoneResult = await api.verifyPhone(code);
			signals.isLoadingScreenShown.value = false;
			if (verifyPhoneResult.alert) {
				await sleep(globals.ANIMATION_WAIT_TIME);
				void showDialog(verifyPhoneResult.alert);
			}
			if (!verifyPhoneResult.success) {
				return;
			}
			signals.login.value = {
				...login,
				phone: phone,
			};
			if (document.referrer && globals.params.action === "changePhone") {
				window.location.href = document.referrer;
			}
		} catch (error) {
			api.handleApiError(error);
			signals.isLoadingScreenShown.value = false;
		}
	};

	void resend(formattedNewPhoneNumber);
}

export async function deleteAccount(): Promise<void> {
	await showDialog(t("willDeleteAllData"), {
		countdown: 5,
		showCancel: true,
	});
	await sleep(globals.ANIMATION_WAIT_TIME);
	const password = await showPrompt(t("enterThePassword"), {
		type: "password",
	});
	await sleep(globals.ANIMATION_WAIT_TIME * 2);
	const login = signals.login.value;
	const expectedConfirmation =
		login.email || login.phone?.slice(-4) || login.username;
	const confirmation = await showPrompt(
		t("enterToConfirmDeletion", { value: expectedConfirmation }),
	);
	if (confirmation !== expectedConfirmation) {
		void showDialog(
			t("yourInputDoesNotMatch", {
				expectedInput: expectedConfirmation,
				userInput: confirmation,
			}),
			{ title: t("error") },
		);
		return;
	}
	const captchaResponse = await showCaptcha();
	signals.isLoadingScreenShown.value = true;
	try {
		const data = await api.deleteAccount(password, captchaResponse);
		if (data.alert) {
			await showDialog(data.alert);
		}
		if (!data.success) {
			return;
		}
		void logOut();
	} catch (error) {
		api.handleApiError(error);
	} finally {
		signals.isLoadingScreenShown.value = false;
	}
}

export function encodeData(
	data: Record<string, string | null | undefined>,
): string {
	const array: string[] = [];
	for (const key in data) {
		const value = data[key];
		if (!value) {
			continue;
		}
		array.push(key + "=" + encodeURIComponent(value));
	}
	return array.join("&");
}

export function formatEmail(email: string): string {
	return email
		.toLowerCase()
		.replace(/\s/g, "")
		.replace(/\.con$/, ".com");
}

export function formatPhoneNumber(phoneNumber: string): string {
	const rmPrefix = (prefixes: string[]): void => {
		for (const prefix of prefixes) {
			if (phoneNumber.startsWith(prefix)) {
				phoneNumber = phoneNumber.substring(prefix.length);
			}
		}
	};

	rmPrefix(["+86", "0086"]);
	const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, "");
	if (
		formattedPhoneNumber.length !== 11 ||
		phoneNumber.startsWith("+") ||
		phoneNumber.startsWith("00")
	) {
		return "";
	}
	return formattedPhoneNumber;
}

export function handleKeyboardClick(
	onClick: () => void,
): (event: KeyboardEvent | React.KeyboardEvent<Element>) => void {
	return (event: KeyboardEvent | React.KeyboardEvent<Element>): void => {
		switch (event.key) {
			case "Enter":
			case " ": {
				onClick();
				event.preventDefault();
				break;
			}
			default:
				break;
		}
	};
}

export function loadJs(src: string): Promise<void> {
	return new Promise((resolve, reject): void => {
		if (globals.loadedJs.has(src)) {
			resolve();
			return;
		}
		globals.loadedJs.add(src);
		setTimeout((): void => {
			const newScript = document.createElement("script");
			newScript.src = src;
			newScript.onerror = reject;
			newScript.onload = (): void => {
				resolve();
			};
			document.body.appendChild(newScript);
		}, 1);
	});
}

export async function logOut(): Promise<void> {
	signals.isLoadingScreenShown.value = true;
	try {
		await api.logOut();
		localStorage.clear();
		window.location.reload();
	} catch (error) {
		api.handleApiError(error);
	} finally {
		signals.isLoadingScreenShown.value = false;
	}
}

export function showCaptcha(): Promise<CaptchaResponse> {
	return new Promise<CaptchaResponse>((resolve) => {
		if (navigator.userAgent.includes("Cypress")) {
			resolve({ ticket: "test-ticket" });
			return;
		}
		if (windowExt.TencentCaptcha) {
			const captcha = new windowExt.TencentCaptcha(
				"2083026945",
				(captchaResponse: CaptchaResponse) => {
					if (captchaResponse.ret !== 0 || !captchaResponse.ticket) {
						return;
					}
					resolve(captchaResponse);
				},
			);
			captcha.show();
		} else {
			globals.captchaCallback = resolve;
			signals.isCaptchaShown.value = true;
		}
	});
}

export function showDialog(
	text: string | JSX.Element,
	options: DialogInfo = {},
	callbacks?: typeof globals.dialogCallbacks,
): Promise<void> {
	return new Promise<void>((resolve) => {
		if (callbacks) {
			globals.dialogCallbacks = callbacks;
		}
		if (!globals.dialogCallbacks.ok) {
			globals.dialogCallbacks.ok = (): void => {
				resolve();
			};
		}
		if (typeof text === "string") {
			options.text = text;
		} else {
			options.text = renderToStaticMarkup(text);
		}
		signals.dialog.value = options;
	});
}

export function showPrompt(
	text: string,
	options: PromptInfo = {},
	inlineAction: typeof globals.promptInlineAction = null,
): Promise<string> {
	return new Promise<string>((resolve) => {
		globals.promptCallback = resolve;
		globals.promptInlineAction = inlineAction;
		options.text = text;
		signals.prompt.value = options;
	});
}

export function sleep(delay: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}
