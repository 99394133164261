import { DialogInfo, DynamicInfo, LoginInfo, PromptInfo } from "@/types";
import { signal } from "@preact/signals";

export const dialog = signal<DialogInfo>({});
export const dynamicInfo = signal<DynamicInfo | null>(null);
export const isCaptchaShown = signal(false);
export const isLoadingScreenShown = signal<boolean>(
	window.location.pathname !== "/login",
);
export const login = signal<LoginInfo>({});
export const prompt = signal<PromptInfo>({});
