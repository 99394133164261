import ButtonBlock from "@/components/button-block";
import styles from "@/components/qr-code.module.css";
import { sleep } from "@/utils";
import { t } from "i18next";
import { QRCodeSVG } from "qrcode.react";
import {
	JSX,
	MouseEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";

interface QrCodeProps {
	buttonText?: string;
	description?: string;
	qrCode: string;
	scrollIntoView?: boolean;
	show: boolean;
	size?: number;
	onClick: MouseEventHandler<HTMLButtonElement>;
}

function QrCode({
	buttonText = "paid",
	description = "",
	qrCode,
	scrollIntoView = true,
	show,
	size = 155,
	onClick,
}: QrCodeProps): JSX.Element {
	const [loaded, setLoaded] = useState<boolean>(false);

	const qrCodeRef = useRef<HTMLDivElement>(null);

	const focusQrCode = useCallback(async (): Promise<void> => {
		if (!qrCode) {
			return;
		}
		setLoaded(true);
		await sleep(0);
		if (!qrCodeRef.current) {
			return;
		}
		if (scrollIntoView) {
			qrCodeRef.current.scrollIntoView();
		}
		qrCodeRef.current.focus();
	}, [qrCode, qrCodeRef, scrollIntoView]);

	useEffect(() => {
		void focusQrCode();
	}, [focusQrCode]);

	if (!show || (qrCode && !loaded)) {
		return <></>;
	}
	return (
		<div className={styles["qr-code-block"]}>
			{qrCode && (
				<div
					ref={qrCodeRef}
					className={styles["qr-code"]}
					aria-label={t(description || "scanThisQrCodeToPay")}
				>
					<QRCodeSVG
						size={size}
						value={qrCode}
					/>
				</div>
			)}
			{description && (
				<div className={styles["description"]}>{t(description)}</div>
			)}
			<ButtonBlock onClick={onClick}>{t(buttonText)}</ButtonBlock>
		</div>
	);
}

export default QrCode;
