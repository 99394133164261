import PromptInlineAction from "@/components/prompt-inline-action";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { Dialog } from "@headlessui/react";
import { useSignalEffect } from "@preact/signals";
import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, FormEvent, JSX, useId, useRef, useState } from "react";

function Prompt(): JSX.Element {
	const id = useId();

	const [inputValue, setInputValue] = useState<string>("");
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const timeoutId = useRef<number | undefined>(undefined);

	const closeDialog = (): void => {
		window.clearTimeout(timeoutId.current);
		globals.promptCallback = null;
		globals.promptInlineAction = null;
		setInputValue("");
		setIsClosing(true);
		setTimeout(() => {
			signals.prompt.value = {};
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		void globals.promptCallback?.(inputValue);
		closeDialog();
	};

	useSignalEffect(() => {
		window.clearTimeout(timeoutId.current);
		if (signals.prompt.value.countdown) {
			timeoutId.current = window.setTimeout(() => {
				const prompt = signals.prompt.value;
				signals.prompt.value = {
					...prompt,
					countdown:
						prompt.countdown && prompt.countdown > 0
							? prompt.countdown - 1
							: 0,
				};
			}, 1000);
		}
		return (): void => {
			window.clearTimeout(timeoutId.current);
		};
	});

	useSignalEffect(() => {
		if (signals.prompt.value.defaultText) {
			setInputValue(signals.prompt.value.defaultText);
		}
	});

	return (
		<Dialog
			onClose={closeDialog}
			open={!!signals.prompt.value.text}
		>
			<div
				className={clsx(
					"overlay",
					!isClosing && "zoom-in",
					isClosing && "zoom-out",
				)}
			>
				<form
					className="popup msg-box"
					onSubmit={handleSubmit}
				>
					<label
						className="msg-box-txt"
						htmlFor={id}
					>
						{signals.prompt.value.text}
						{signals.prompt.value.inlineActionName && (
							<PromptInlineAction closeDialog={closeDialog} />
						)}
					</label>
					<input
						autoComplete={
							signals.prompt.value.autoComplete || "off"
						}
						className="msg-box-input"
						data-autofocus
						id={id}
						inputMode={
							signals.prompt.value.type === "tel"
								? "numeric"
								: undefined
						}
						type={signals.prompt.value.type || "text"}
						value={inputValue}
						onChange={handleInputChange}
					/>
					<div className="btn-bar">
						<button
							type="button"
							onClick={closeDialog}
						>
							{t("cancel")}
						</button>
						<button
							className="default-btn"
							type="submit"
						>
							{t("ok")}
						</button>
					</div>
				</form>
			</div>
		</Dialog>
	);
}

export default Prompt;
