import styles from "@/components/tab.module.css";
import { handleKeyboardClick } from "@/utils";
import { t } from "i18next";
import { JSX } from "react";

interface TabProps {
	active: number;
	index: number;
	text: string;
	updateActive: (newValue: number) => void;
}

function Tab({ active, index, text, updateActive }: TabProps): JSX.Element {
	const handleClick = (): void => {
		updateActive(index);
	};

	return (
		<span
			className={styles["tab-item"]}
			tabIndex={0}
			role="tab"
			aria-selected={active === index}
			onClick={handleClick}
			onKeyDown={handleKeyboardClick(handleClick)}
		>
			{t(text)}
		</span>
	);
}

export default Tab;
