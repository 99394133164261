import styles from "@/components/sidebar.module.css";
import { globals } from "@/globals";
import {
	faListUl,
	faShieldHalved,
	faStar,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import i18next, { t } from "i18next";
import { Fragment, JSX } from "react";
import { NavLink } from "react-router-dom";

function Sidebar(): JSX.Element {
	const isFromIos = globals.params.isapp === "ios";

	const navItems = [
		{
			icon: faUser,
			path: "/",
			text: "profile",
			when: true,
		},
		{
			icon: faShieldHalved,
			path: "/security",
			text: "security",
			when: true,
		},
		{
			icon: faStar,
			path: "/premium",
			text: "premium",
			when: !isFromIos,
		},
		{
			icon: faListUl,
			path: "/orders",
			text: "orderHistory",
			when: true,
		},
	] as const;

	const navItemsElem = navItems.map((item) => {
		if (!item.when) {
			return <Fragment key={item.path} />;
		}
		return (
			<NavLink
				className={({ isActive }): string => {
					return clsx(
						styles["nav-item"],
						isActive && styles["active"],
					);
				}}
				draggable={false}
				key={item.path}
				replace
				to={item.path}
			>
				<FontAwesomeIcon
					icon={item.icon}
					fixedWidth
				/>
				{t(item.text)}
			</NavLink>
		);
	});

	const products = [
		{
			description: t("limeStartPageDescription"),
			icon: "https://assets.retiehe.com/lime-apple-touch-icon.png",
			nameFull: t("limeStartPage"),
			nameShort: t("limeStart"),
			url: i18next.language.startsWith("zh")
				? "https://intro.limestart.cn/"
				: "https://intro.limestart.page/",
		},
		{
			description: t("airportalDescription"),
			icon: "https://assets.retiehe.com/ap-apple-touch-icon-2.png",
			nameFull: t("airportal"),
			url: "https://www.airportal.cn/",
		},
		{
			description: t("webHostingDescription"),
			icon: "https://assets.retiehe.com/host-icon-512-2.png",
			nameFull: t("webHosting"),
			nameShort: t("webHost"),
			url: "https://host.retiehe.com/",
		},
	];

	const productsElem = products.map((item) => {
		return (
			<a
				className={styles["nav-product"]}
				href={item.url}
				key={item.nameFull}
				rel="noreferrer"
				target="_blank"
				title={item.nameFull + " - " + item.description}
				aria-label={item.nameFull}
			>
				<img
					src={item.icon}
					alt=""
					draggable={false}
					width={32}
					height={32}
				/>
				<div>{item.nameShort || item.nameFull}</div>
			</a>
		);
	});

	const footerLinks = [
		{
			text: "termsOfService",
			url: "https://docs.retiehe.com/tos.html",
		},
		{
			text: "privacyPolicy",
			url: "https://docs.retiehe.com/privacy.html",
		},
	];
	const footerLinksElem = footerLinks.map((item) => {
		return (
			<a
				className={styles["link"]}
				key={item.text}
				href={item.url}
				rel="noreferrer"
				target="_blank"
			>
				{t(item.text)}
			</a>
		);
	});

	return (
		<aside className={styles["sidebar"]}>
			<nav>{navItemsElem}</nav>
			<footer>
				<div className="line"></div>
				<section>
					<h2>{t("retieheProducts")}</h2>
					<div className={styles["nav-products"]}>{productsElem}</div>
				</section>
				<div className="line"></div>
				<div className={styles["links"]}>{footerLinksElem}</div>
			</footer>
		</aside>
	);
}

export default Sidebar;
