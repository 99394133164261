import styles from "@/components/header.module.css";
import { globals } from "@/globals";
import * as signals from "@/signals";
import { logOut, showDialog } from "@/utils";
import {
	faArrowRightFromBracket,
	faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { JSX } from "react";

function Header(): JSX.Element {
	const confirmLogOut = async (): Promise<void> => {
		await showDialog(t("confirmLogOut"), {
			showCancel: true,
		});
		void logOut();
	};

	const handleLogOutClick = (): void => {
		void confirmLogOut();
	};

	const handleSupportClick = (): void => {
		window.open("https://support.retiehe.com/");
	};

	const login = signals.login.value;
	return (
		<header className={styles["header"]}>
			<div className={styles["flex-container"]}>
				<a
					href={globals.home}
					draggable="false"
					title={t("home")}
				>
					<span className={styles["icon"]}></span>
				</a>
				<h1>{t("retieheAccount")}</h1>
			</div>
			<div className={styles["flex-container"]}>
				<p>
					{login.username
						? t("welcome") +
							(login.name ||
								login.email ||
								login.phone ||
								login.username)
						: ""}
				</p>
				<button
					type="button"
					onClick={handleLogOutClick}
				>
					<FontAwesomeIcon icon={faArrowRightFromBracket} />
					{t("logOut")}
				</button>
				<button
					type="button"
					onClick={handleSupportClick}
				>
					<FontAwesomeIcon icon={faHeadset} />
					{t("helpAndFeedback")}
				</button>
			</div>
		</header>
	);
}

export default Header;
